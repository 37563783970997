module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-gdpr-cookies@2.0.9_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react_3ea1b99e2ef71ea6d3e3dafe5ff4bf7a/node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"GTM-MTJN26S","cookieName":"gatsby-gdpr-google-tagmanager","dataLayerName":"dataLayer"},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-do_9e9fee829b5b2d2a5920326ce92c1131/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/small-logo-no-wording.svg","name":"sway.dm","short_name":"sway.dm","start_url":"/","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"547dec1c5ff88f5254670746e2930da4"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.1_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@1_e590a68de2f1cf2a65e5d3a4edd92d9b/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
